<template>
    <basic-layout>
        <v-container>
            <v-row>
                <v-col>
                    <v-app-bar app flat  color="white">
                        <v-icon @click.prevent="$router.go(-1)" class="mr-3">mdi-arrow-left</v-icon>
                        <v-toolbar-title>
                            <span  class="font-weight-light primary--text">{{title}}</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu left bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item  @click="sortAscending">
                                    <v-list-item-title>Sort Ascending</v-list-item-title>
                                </v-list-item>
                                <v-list-item  @click="sortDescending">
                                    <v-list-item-title>Sort Descending</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-app-bar>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-if="!payroll_data" class="fill-height" fluid>
            <v-row  align="center" justify="center">
                <v-col>
                    <div class="font-weight-light primary--text text-center">
                        <div class="text-center" align="center" justify="center">
                            <v-progress-circular :size="50" :width="5" color="green" indeterminate></v-progress-circular>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="mt-0"  v-else>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <div class="">
                        <div>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="search"
                                            solo
                                            label="Search payslip"
                                            clearable
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row  align="center" justify="center"  >
                                <v-col cols="12">
                                    <v-card @click="showDetails(item.payroll_number,item.title)" class="mt-2 " v-for="(item, index) in resultQuery" :key="index">
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="10" >
                                                    <small class="primary--text"><i>Payroll No.: {{item.payroll_number}}</i></small>
                                                    <h4 class="primary--text">{{item.title}}</h4>
                                                    <p class="primary--text" v-if="item.description"><i>{{item.description}}</i></p>
                                                </v-col>
<!--                                                <v-col class="text-end" cols="2" @click="print(item.employee_id,item.payroll_number)">-->
<!--                                                    <v-icon class="ml-2" color="primary" >mdi-printer</v-icon>-->
<!--                                                </v-col>-->
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </basic-layout>
</template>

<script>
    import user from "../../api/user";
    import payrolls from "../../api/payrolls";
    import printpayroll from "../../pdf/printpayroll";
    export default {
        name: "PayslipList",
        data() {
            return {
                title: "Payslip List",
                search: null,
                form: {
                    employee_id : '',
                },
            }
        },
        computed: {

            user(){
                return this.$store.getters.getCurrentUser;
            },
            payroll_data(){
                return this.$store.getters.getPayrollList;
            },
            resultQuery(){
                if(this.search){
                    return this.payroll_data.filter((item)=>{
                        return this.search.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
                    })
                }else{
                    return this.payroll_data;
                }
            }
        },
        methods: {
            sortAscending(){
                this.payroll_data.sort((a, b) => (a.payroll_number > b.payroll_number) ? 1 : -1);
                //this.load_data();
            },
            sortDescending(){
                this.payroll_data.sort((a, b) => (a.payroll_number < b.payroll_number) ? 1 : -1)
                //this.load_data();
            },
             print(id,payroll){
                 payrolls.payrollslip({employee_id: id, payroll: payroll})
                     .then(response => {
                         printpayroll.printPayrollSlip( response.data[0], this.user);
                     })
            },
            showDetails(payroll_number,title){
                this.$store.commit('setPayroll',payroll_number);
                this.$store.commit('setPayrollTitle',title);
                this.$router.push({name:'PayslipDetail'});
               // console.log("payroll number ",this.$store.getters.getPayroll,'-',id)
            },
            load_data() {
                if(!this.$store.getters.getPayrollList){
                    user.auth()
                        .then(() =>{
                            this.form.employee_id = this.user.employee_id;
                            payrolls.payrolllist(this.form)
                                .then(response =>{
                                    this.$store.commit('setPayrollList',response.data);
                                })
                        })
                }

            },
        },
        mounted() {
            this.load_data();
        },
    }
</script>

<style scoped>

</style>